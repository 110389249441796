<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="search"><i class="fa fa-refresh"></i></div>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-select v-model="customer.byField" size="mini"
                       placeholder="검색조건"
                       style="width:120px;;margin-left: 10px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="회원아이디" :value="managerConst.User.SEARCH_BY_USERNAME">회원아이디</el-option>
                <el-option label="회원닉네임" :value="managerConst.User.SEARCH_BY_NICKNAME">회원닉네임
                </el-option>
            </el-select>
            <el-input type="text" size="mini" v-model="customer.searchString" placeholder="아이디/닉네임"
                      style="width: 150px;margin-left: 5px"></el-input>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색
            </el-button>
            <el-select v-model="customer.status" @change="getCustomerList" size="mini"
                       placeholder="답변상태"
                       style="width:120px;margin-left: 5px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="미확인" :value="managerConst.Customercenter.STATUS_UNCONFIRMED">미확인</el-option>
                <el-option label="답변중" :value="managerConst.Customercenter.STATUS_CHECKED">답변중</el-option>
                <el-option label="답변완료" :value="managerConst.Customercenter.STATUS_REPLYED">답변완료</el-option>
            </el-select>

            <el-button type="danger" size="mini" style="margin-left: 20px" @click="updateDisplayNone(1)">삭제(24시)</el-button>
            <el-button type="danger" size="mini" style="margin-left: 5px" @click="updateDisplayNone(2)">삭제(48시)</el-button>
            <el-button type="danger" size="mini" style="margin-left: 5px" @click="updateDisplayNone('all')">전체삭제</el-button>

        </div>

        <div class="data" style="overflow-y: scroll;max-height: 750px">
            <div class="customer_panel" v-for="item in customerList">
                <div style="width: 15%; margin-left: 10px;">
                  <el-button size="mini" type="warning" v-if="item.user"
                             @click="editUser(item.user.id,managerConst.MODE_EDIT)">
                    <span v-if="item.user.sticker" style="margin-right: 5px"
                          :style="{color:item.user.scolor}"> {{ item.user.sticker }}</span>
                    <i class="fa fa-user-circle"> {{ item.user.nickname }}</i>
                  </el-button>
                  <el-button size="mini" type="info" v-if="item.agent">
                    [총판] {{ item.agent.nickname }}
                  </el-button>
                </div>
                <div style="width: 11%;margin-left: 5px;text-align: center;color: #353539" class="badge-warning">
                     문의시간: {{item.createTime|datef('MM-DD HH:mm')}}
                </div>
                <div style="width: 11%;margin-left: 5px;text-align: center;color: #353539" class="badge-warning">
                    답변시간: {{item.updateTime|datef('MM-DD HH:mm')}}
                </div>
                <div style="width: 10%;margin-left: 5px;text-align: center;color: #353539" class="badge-warning">
                    처리자:{{item.operator}}
                </div>
                <div style="width: 10%;margin-left: 5px;text-align: center;color: #353539" class="badge-warning">
                    IP:{{item.ip}}
                </div>
                <div style="width: 10%;color: #ff4d4d" v-if="item.display == managerConst.DISABLE">
                    [삭제되였습니다]
                </div>
                <div style="width: 100%;cursor: pointer" class="direct-chat-text" v-html="item.content" @click="replayCustomercenter(item.id)"> </div>
                <div style="width: 100%;padding: 5px 10px">
                    <el-button size="mini" type="primary"
                               v-if="item.status == managerConst.Customercenter.STATUS_UNCONFIRMED || item.status == managerConst.Customercenter.STATUS_CHECKED"
                               @click="replayCustomercenter(item.id)">
                        <i class="fa fa-pencil"> 답변하기</i>
                    </el-button>
                    <el-button size="mini" type="danger"
                               v-if="item.status == managerConst.Customercenter.STATUS_UNCONFIRMED"
                               @click="changeCustomerStatus(item.id,managerConst.Customercenter.STATUS_CHECKED)">
                        <i class="fa fa-bell-o"> 알람끄기</i>
                    </el-button>
                </div>
            </div>

        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20,50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import {getHonorCasinoBetlist, sysData} from "../../network/manager/honorCasinoRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {getRegisterCodeList, updateRegisterCode} from "../../network/manager/registerCodeRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {getRankList} from "../../network/manager/rankRequest";
    import {getGroupList, updateGroup} from "../../network/manager/groupRequest";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {
        displayNoneAll,
        getCustomerCetnerList,
        updateCustomerCetner
    } from "../../network/manager/customercenterRequest";
    import CustomerReplayToast from "../../components/administrator/UserPanel/CustomerReplayToast";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$customerReplayEditToast = CustomerReplayToast

    export default {
        name: "ManagerCustomerCenter",
        mixins: [manager],
        components: {
            DateSelectorComp,
            ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                customer: {},
                customerList: [],
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
            }
        },
        methods: {
            search() {
                this.customer.status = null
                this.getCustomerList()
            },
            getCustomerList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.customer.startDate = this.startDate
                this.customer.endDate = this.endDate
                getCustomerCetnerList(this.customer, this.pageNum, this.pageSize).then(res => {
                    this.customerList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            replayCustomercenter(id){
                this.$customerReplayEditToast({'customerId': id})
            },
            changeCustomerStatus(id, status){
                let cc = {};
                cc.id = id;
                cc.status = status;
                updateCustomerCetner(cc).then(res=>{
                    this.getCustomerList()
                })
            },
            updateDisplayNone(day){
                this.$confirm('삭제하시겠습니까?', '고객센터 삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let cc = {}
                    if(day != 'all'){
                        cc.startDate =  this.$moment().subtract(day, 'days').format("YYYY-MM-DD");
                    }
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    displayNoneAll(cc).then(res=>{
                        console.log(res)
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                        loadingInstance.close()
                        this.getCustomerList()
                    })
                });
            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            editPartner(partnerId, mode) {
                this.$partnerEditToast({'partnerId': partnerId, 'mode': mode})
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getCustomerList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getCustomerList();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
        },
        created() {
            this.startDate = this.$moment().subtract(7,'days').format('yyyy-MM-DD');
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.getCustomerList()
            this.$bus.$on('customerReplayComplate',()=> {
                this.getCustomerList()
            })
        },
    }
</script>

<style scoped>
</style>