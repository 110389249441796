<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>쪽지</sub-title>

                <div class="message">
                    <div class="message_btns">
                        <button class="btn-ok" @click="checkedAll()">전체읽음처리</button>
                        <button class="btn-del" @click="deleteAll()">전체삭제</button>
                    </div>
                    <div class="message_cont" v-for="(item, index) in messageList" :key="index">
                        <div class="m_new">
                            <div class="time">

                                <div class="t2">{{item.createTime|datef('MM월DD일 HH시mm분')}}</div>
                                <div class="t1"  v-if="item.checked ===  sportsConst.UNCHECKED">미확인</div>
                                <div class="t3"  v-if="item.checked ===  sportsConst.UNCHECKED">
                                    <button class="btn-ok" @click="checkedMessageById(item.id)">읽음처리</button>
                                </div>
                                <div class="t3"  v-if="item.checked !==  sportsConst.UNCHECKED">
                                    <button class="btn-del"  @click="deleteMessageById(item.id)">삭제</button>
                                </div>
                            </div>
                            <div class="cont" v-html="item.content" :class="{'bg-light':item.checked ===  sportsConst.UNCHECKED}">
                            </div>
<!--                            <div class="cont" v-if="item.type === sportsConst.MESSAGE_TYPE_PUBLIC"-->
<!--                                 v-html="item.publicMessage.content"  :class="{'bg-light':item.checked ===  sportsConst.UNCHECKED}">-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>


                <!--페이지-->
                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"
                            v-if="messageList.length > 0"></pagination>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import SubTitle from "../../components/SubTitle";
    import Pagination from "../../components/pagenation/Pagination";
    import {
        checkedMessageAll,
        checkedMessageById,
        deleteMessageAll,
        deleteMessageById,
        getMessageList
    } from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

    export default {
        name: "Message",
        mixins: [postionMixin],
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, Pagination, SubTitle, RightBarComp
        },
        data() {
            return {
                messageList: [],
                pageNum: 1,
                pageSize: 10,
                total: 1,
                sportsConst,
                position: "쪽지",
            }
        },
        methods: {
            checkedMessageById(id) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                checkedMessageById(id).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.initMessageList();
                        this.$store.dispatch('actionUnCheckedMessageCount')
                    }
                })
            },
            deleteMessageById(id) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                deleteMessageById(id).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.initMessageList();
                        this.$store.dispatch('actionUnCheckedMessageCount')
                    }
                })
            },
            checkedAll() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                checkedMessageAll().then(res => {
                    if (res.data.success) {
                        this.$store.commit(RECEIVE_HIDE_LOADING)
                        this.initMessageList();
                        this.$store.dispatch('actionUnCheckedMessageCount')
                    }
                })
            },
            deleteAll() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                deleteMessageAll().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.initMessageList();
                        this.$store.dispatch('actionUnCheckedMessageCount')
                    }
                })
            },
            initMessageList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getMessageList(this.pageNum, this.pageSize).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.total = res.data.total
                        this.messageList = res.data.data
                    }
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.initMessageList()
            },
        },
        created() {
            this.initMessageList()
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .message {
        width: 100%;
    }


    .message .message_btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0;
    }

    .message .message_btns button {
        width: 120px;
        height: 30px;
    }

    .message .message_btns .btn-ok {
        --background-color: var(--btn01Bg);
        border: 1px solid #4e4e4e;
        border-radius: 3px;
        margin-left: 5px;
        color: #ffa500;
    }

    .message .message_btns .btn-del {
       --background-color: #ff0000;
        margin-left: 16px;
        border: 1px solid #4e4e4e;
        border-radius: 3px;
        color: #ff4d4d;
    }

    .message .m_new {
        width: 100%;
        background-color: var(--noticeBg);
        padding: 16px;
        color: #fff9ff;
        border-bottom: 1px solid #616161;
    }
    .message .m_new .time{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        padding: 10px 0;
    }
    .message .m_new .time .t1{
        width: 60px;
        color: #ff4d4d;
    }
    .message .m_new .time .t2{
        --background-color: #ffa500;
        margin-right: 10px;
        padding: 5px;
        border-radius: 10px;
        color: #ffffff;
    }
    .message .m_new .time .t3{
        width: 120px;
    }
    .message .m_new .cont{
        padding: 16px;
    }
    .message .m_new .time button{
        padding: 3px 5px;
    }
    .message .m_new .time .btn-ok{
        background-color: #f8b741;
        color: #353535;
        border-radius: 3px;
    }
    .message .m_new .time .btn-del{
        background-color: #ff0000;
        border-radius: 3px;
    }
    .message .bg-light{
        background-color: var(--noticeBg2);
    }


</style>